import {
  Outlet,
  useLocation,
} from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";

/**
 * https://stackoverflow.com/questions/71808023/transition-between-routes-in-react-router-dom-v6-3
 */

const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5
};

export default function AnimationLayout(){
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};
