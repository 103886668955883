import { useEffect, useState } from 'react'
import '../styles/topics.css'

const dummydata = [
  {title:'イード･アル=アドハーのご案内', 'short': '2022/07/10 Eid - al-Adha Prayer Start from 10/7/2022 AM9:00 令和４年7月10日日曜日、午前9時から。…', 
    content: `イード･アル=アドハーのご案内
2022/07/10

Eid al-Adha Prayer Start from 10/7/2022 AM9:00
　　令和４年7月10日日曜日、午前9時から。

1年に１回だけ行われるイスラム教で定められた宗教的な祝日で御座います。
預言者アブラハム（イブラーヒーム）が、神様からの命を受けて、息子のイスマーイールを、アッラーフ（神様）への犠牲として捧げた史実を世界的に記念する日です。
イスラム教を信仰するムスリムのラマダーン明けのイード（祝祭）の1つでもあり、イード・アル＝フィトル（断食明け記念日）と同様に、イード・アル＝アドハーは、短い説教をともなう祈祷（フトゥバ）から始まります。
イード・アル=フィトルより長期間にわたるため、大イードなどとも呼ばれます。
また、日本では犠牲祭と意訳されます。
イード・アル＝アドハーはヒジュラ暦の12月10日から4日間にわたって行なわれ、この日は世界中のムスリムによるサウジアラビアのメッカへの毎年恒例の巡礼においてアラファト山を降りる日の翌日にあたり、すなわちハッジの最終日にあたり、また、巡礼に参加していないムスリムも清い動物を1匹生贄として捧げ、この日をアッラーフに感謝し盛大に祝います。

近隣住民の皆様のご迷惑となりません様、最大限の注意を払い、記念日をお祝いできます様に、対応したいと思いますので、ご理解の程、宜しくお願い致します。`, 
    imgSrc: require('../Assets/topic1.jpg')},
  {title:'『イード・アル・フィトル』のご案内', 'short': '2022/05/01 - Eid ul-Fitr Prayer Start from 2/5/1443（2022） AM 9:00 この度、令和４年５月２日月曜日に、 神戸ムス…', 
    content: `『イード・アル・フィトル』のご案内
2022/05/01

Eid ul Fitar Announcement
Dear All Assalam u Aalaekum

Eid ul Fitar in 1443 Hijri (2022) in Japan will be on Monday, 2th of May 2022.
Eid Prayer will be held at 09:00 Am in Kobe Muslim Mosque.
Eid Mubarak to All

この度、令和４年5月２日月曜日に、 神戸ムスリムモスクにおいて、
『イード・アル・フィトル』と呼ばれます、ラマダン（断食月）明けのお祝いが御座います。
近隣住民の皆様方には、騒音や交通の影響等でご迷惑をおかけする事の無いように、注意しながらお祝いをさせて頂きたいので、何卒、御容赦の程、心より宜しくお願い致します。
『イード・アル・フィトル』のお祝いは、1か月間続きました断食月、ラマダンの終了に際する大切な式典で御座いますので、ご理解下さいましたら幸いで御座います。
何か緊急時の連絡事が御座いましたら下記に御問い合わせの程、お願い申し上げます。
心より、ありがとうございました。

宗教法人　神戸ムスリムモスク
電話：　０７８－２３１－６０６０
予定時間：　２０２２年５月２日　月曜日　午前９時より、午前１１時　予定`, 
    imgSrc: require('../Assets/topic2.jpg')},
  {title:'ラマダンタイムテーブル', 'short': '1443年（2022年）のラマダンは４月３日より開始します。ラマダンのタイムテーブルを特別ページで掲載しま…', 
    content: `ラマダンタイムテーブル
2022/04/02

1443年（2022年）のラマダンは４月３日より開始します。ラマダンのタイムテーブルを特別ページで掲載しましたので、ご利用ください。`, 
    imgSrc: require('../Assets/topic3.jpg')},
  {title:'イスラム文化センターからのお知らせ', 'short': '2017/09/16 - 神戸ムスリムモスク付属のイスラム文化センターでは、アラビア語学習の講座を行っております。詳しくは当モ…', 
    content: `イスラム文化センターからのお知らせ
2017/09/16

神戸ムスリムモスク付属のイスラム文化センターでは、アラビア語学習の講座を行っております。詳しくは当モスクにお気軽にお問い合わせ下さい。`, 

    imgSrc: require('../Assets/topic4.jpg')}
]


function TopicOverlay(props){
  useEffect(_=>console.log(props), [])
  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" style={{minWidth:'60%'}}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">{props.overlayContent.title}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {props.overlayContent.imgSrc && <img src={props.overlayContent.imgSrc}/>}
          <div className="modal-body">
            <ul>
              {props.overlayContent.content.split('\n').map((str, i) => {
                return str?<li className='m-0' key={i}>{str}</li>:<br/>
              })}
            </ul>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

function TopicCard(props){
  return (
    <div className="card m-3" style={{width: "18rem"}}>
      <img className="card-img-top" src={props.imgSrc} alt="Card image cap"/>
      <div className="card-body" style={{textAlign: 'justify'}}>
        <h5 className="card-title text-center">{props.title}</h5>
        <p className="m-0">{props['short']}</p>
        <div className='text-center'>
          <a href="#" className="btn-rounded" data-bs-toggle="modal" data-bs-target="#exampleModal"
            onClick={_=>props.setOverlayContent({title:props.title, content:props.content, imgSrc:props.imgSrc})}
          >Open</a>
        </div>
      </div>
    </div>
  )
}

export default function Topics(props){
  const [overlayContent, setOverlayContent] = useState({title:'some title', content:'this is content', imgSrc:''})

  return (
    <div className={props.classname}>
      <TopicOverlay overlayContent={overlayContent}/>
      <section id="facts">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3 className="section-title">トピックス</h3>
    {/*<p className="section-description">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque{}</p>*/}
            <div className='topic-wrapper d-flex flex-row flex-wrap justify-content-center align-items-center'>
              {
                  dummydata.concat(dummydata).map((e, i)=> (
                    <TopicCard key={i} setOverlayContent={setOverlayContent} imgSrc={e.imgSrc} title={e.title} short={e['short']} content={e.content}/>
                  ))
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
