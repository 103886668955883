import '../styles/main.css'

export default function Prayers(props){
  const getDate = () => {
    const d = new Date()
    return `${d.getFullYear()}年${d.getMonth()+1}月${d.getDate()}日`
  }
  return (
      <section id="services">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3 className={`section-title ${props.classname}`}>礼拝時間と見学について</h3>
            <p className="section-description m-0 pb-5">金曜礼拝    フトバ：12:30    PM    ジュマー：1:10    PM <br/>Friday    Khutbah start    12:30    PM    Jummah Prayer Time 1:10    PM</p>

            <p className='table-heading'>本日 TODAY - {getDate()}</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '600px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Date</th>
                    <th>Fajr</th>
                    <th>Sunrise</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>20</td>
                  <td>5:16</td>
                  <td>6:40</td>
                  <td>12:14</td>
                  <td>3:22</td>
                  <td>5:47</td>
                  <td>7:07</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <p className='table-heading'>神戸ムスリムモスクの礼拝時間 Iqama Times</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '400px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Fajr</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6:00</td>
                    <td>12:30</td>
                    <td>4:00</td>
                    <td>5:44</td>
                    <td>7:45</td>
                  </tr>
                </tbody>
              </table>
            </div>

          <p className='table-heading'>2023年2月</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '600px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Date</th>
                    <th>Fajr</th>
                    <th>Sunrise</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>16</td>
                  <td>5:20</td>
                  <td>6:44</td>
                  <td>12:14</td>
                  <td>3:19</td>
                  <td>5:43</td>
                  <td>7:03</td>
                  </tr>
                  <tr>
                  <td>17</td>
                  <td>5:19</td>
                  <td>6:43</td>
                  <td>12:14</td>
                  <td>3:20</td>
                  <td>5:44</td>
                  <td>7:04</td>
                  </tr>
                  <tr>
                  <td>18</td>
                  <td>5:18</td>
                  <td>6:42</td>
                  <td>12:14</td>
                  <td>3:20</td>
                  <td>5:45</td>
                  <td>7:05</td>
                  </tr>
                  <tr>
                  <td>19</td>
                  <td>5:17</td>
                  <td>6:41</td>
                  <td>12:14</td>
                  <td>3:21</td>
                  <td>5:46</td>
                  <td>7:06</td>
                  </tr>
                  <tr>
                  <td>20</td>
                  <td>5:16</td>
                  <td>6:40</td>
                  <td>12:14</td>
                  <td>3:22</td>
                  <td>5:47</td>
                  <td>7:07</td>
                  </tr>
                  <tr>
                  <td>21</td>
                  <td>5:15</td>
                  <td>6:38</td>
                  <td>12:13</td>
                  <td>3:22</td>
                  <td>5:48</td>
                  <td>7:08</td>
                  </tr>
                  <tr>
                  <td>22</td>
                  <td>5:13</td>
                  <td>6:37</td>
                  <td>12:13</td>
                  <td>3:23</td>
                  <td>5:49</td>
                  <td>7:08</td>
                  </tr>
                  <tr>
                  <td>23</td>
                  <td>5:12</td>
                  <td>6:36</td>
                  <td>12:13</td>
                  <td>3:23</td>
                  <td>5:50</td>
                  <td>7:09</td>
                  </tr>
                  <tr>
                  <td>24</td>
                  <td>5:11</td>
                  <td>6:35</td>
                  <td>12:13</td>
                  <td>3:24</td>
                  <td>5:51</td>
                  <td>7:10</td>
                  </tr>
                  <tr>
                  <td>25</td>
                  <td>5:10</td>
                  <td>6:34</td>
                  <td>12:13</td>
                  <td>3:25</td>
                  <td>5:52</td>
                  <td>7:11</td>
                  </tr>
                  <tr>
                  <td>26</td>
                  <td>5:09</td>
                  <td>6:33</td>
                  <td>12:13</td>
                  <td>3:25</td>
                  <td>5:52</td>
                  <td>7:12</td>
                  </tr>
                  <tr>
                  <td>27</td>
                  <td>5:08</td>
                  <td>6:31</td>
                  <td>12:13</td>
                  <td>3:26</td>
                  <td>5:53</td>
                  <td>7:13</td>
                  </tr>
                  <tr>
                  <td>28</td>
                  <td>5:06</td>
                  <td>6:30</td>
                  <td>12:12</td>
                  <td>3:26</td>
                  <td>5:54</td>
                  <td>7:13</td>
                  </tr>
                  <tr>
                  <td>29</td>
                  <td>5:06</td>
                  <td>6:30</td>
                  <td>12:12</td>
                  <td>3:26</td>
                  <td>5:54</td>
                  <td>7:13</td>
                  </tr>
                  </tbody>
              </table>
            </div>

            <p className='table-heading'>翌月：2023年3月</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '600px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Date</th>
                    <th>Fajr</th>
                    <th>Sunrise</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>

                <tbody>
                <tr>
                <td>1</td>
                <td>5:05</td>
                <td>6:29</td>
                <td>12:12</td>
                <td>3:27</td>
                <td>5:55</td>
                <td>7:14</td>
                </tr>
                <tr>
                <td>2</td>
                <td>5:05</td>
                <td>6:29</td>
                <td>12:12</td>
                <td>3:27</td>
                <td>5:55</td>
                <td>7:14</td>
                </tr>
                <tr>
                <td>3</td>
                <td>5:03</td>
                <td>6:26</td>
                <td>12:12</td>
                <td>3:28</td>
                <td>5:57</td>
                <td>7:16</td>
                </tr>
                <tr>
                <td>4</td>
                <td>5:01</td>
                <td>6:25</td>
                <td>12:12</td>
                <td>3:28</td>
                <td>5:58</td>
                <td>7:17</td>
                </tr>
                <tr>
                <td>5</td>
                <td>5:00</td>
                <td>6:24</td>
                <td>12:11</td>
                <td>3:29</td>
                <td>5:59</td>
                <td>7:18</td>
                </tr>
                <tr>
                <td>6</td>
                <td>4:59</td>
                <td>6:22</td>
                <td>12:11</td>
                <td>3:29</td>
                <td>5:59</td>
                <td>7:19</td>
                </tr>
                <tr>
                <td>7</td>
                <td>4:58</td>
                <td>6:21</td>
                <td>12:11</td>
                <td>3:30</td>
                <td>6:00</td>
                <td>7:19</td>
                </tr>
                <tr>
                <td>8</td>
                <td>4:56</td>
                <td>6:20</td>
                <td>12:11</td>
                <td>3:30</td>
                <td>6:01</td>
                <td>7:20</td>
                </tr>
                <tr>
                <td>9</td>
                <td>4:55</td>
                <td>6:18</td>
                <td>12:10</td>
                <td>3:30</td>
                <td>6:02</td>
                <td>7:21</td>
                </tr>
                <tr>
                <td>10</td>
                <td>4:54</td>
                <td>6:17</td>
                <td>12:10</td>
                <td>3:31</td>
                <td>6:03</td>
                <td>7:22</td>
                </tr>
                <tr>
                <td>11</td>
                <td>4:52</td>
                <td>6:16</td>
                <td>12:10</td>
                <td>3:31</td>
                <td>6:04</td>
                <td>7:23</td>
                </tr>
                <tr>
                <td>12</td>
                <td>4:51</td>
                <td>6:14</td>
                <td>12:10</td>
                <td>3:32</td>
                <td>6:04</td>
                <td>7:24</td>
                </tr>
                <tr>
                <td>13</td>
                <td>4:51</td>
                <td>6:14</td>
                <td>12:10</td>
                <td>3:32</td>
                <td>6:04</td>
                <td>7:24</td>
                </tr>
                <tr>
                <td>14</td>
                <td>4:48</td>
                <td>6:12</td>
                <td>12:09</td>
                <td>3:32</td>
                <td>6:06</td>
                <td>7:25</td>
                </tr>
                <tr>
                <td>15</td>
                <td>4:47</td>
                <td>6:10</td>
                <td>12:09</td>
                <td>3:33</td>
                <td>6:07</td>
                <td>7:26</td>
                </tr></tbody>

              </table>
            </div>


          </div>
        </div>
      </section>
  )
}
