import React, {useRef, useEffect, useState} from 'react'
import '../styles/hero.css'

const bgs = ["bg01.jpeg", "bg02.jpeg", "bg03.jpeg", "bg04.jpeg", "bg05.jpeg", "bg06.jpeg", "bg07.jpeg", "bg08.jpeg", "bg09.jpeg", "bg10.jpeg", "bg11.jpeg", "bg12.jpeg", "bg13.jpeg"]

export default function Hero(){

  const [bg, setBg] = useState(0)
  const bgRef = useRef(null)

  useEffect(_=>{
    setInterval(_=>{
      setBg(b=>(b+1)%13)
    }, 3000)
  },[])

  useEffect(_=>{
    bgRef.current.style.backgroundImage = `url(${process.env.PUBLIC_URL}/assets/img/${bgs[bg]})`
  }, [bg])


  return (
    <section id="hero" ref={bgRef}>
      <div className="hero-container" data-aos="zoom-in" data-aos-delay="100" >
        <h1>神戸ムスリムモスク公式HPにようこそ</h1>
        <h2> 当モスクは1935年に建てられた、日本最古のモスクです。</h2>
        <a href="#about" className="btn-get-started">Know More</a>
      </div>
    </section>
  )
}
