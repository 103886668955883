import '../styles/footer.css'

export default function Footer(){
  return (
    <footer id="footer" data-aos="fade-in">
      <div className="footer-top">
        <div className="container">

        </div>
      </div>

      <div className="container">
        <div className="copyright">
          <h3 className='m-0'>神戸ムスリムモスク</h3>
          〒650-0004 兵庫県神戸市中央区中山手通２-２５-１４<br/>TEL:078-231-6060
        </div>
    {/*<div className="credits">
          Designed by <a href="https://github.com/hyper-trophy/">Harsh Suthar</a>
        </div>*/}
      </div>
    </footer>
  )
}
