// import logo from './logo.svg';
import {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link,
  // Outlet,
  // useLocation,
  Navigate
} from "react-router-dom";
import './App.css';
import AnimationLayout from './components/AnimateLayout';
import Footer from './components/Footer';
import Hero from './components/hero';
import Main from './components/main';
import Navbar from './components/Navbar';
import Topics from './components/topics';
import Prayers from './components/Prayers';

function Home(props){
  return (
    <>
      <Hero/>
      <Main/>
    </>
  )
}

function App() {
  useEffect(()=>{
    window.AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })
  }, [])


  return (
    <Router>
      <Navbar/>
        <Routes>
          <Route element={<AnimationLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/topics" element={<Topics classname='mt-5'/>} />
            <Route exact path="/prayertiming" element={<Prayers classname='mt-5'/>} />
            <Route exact path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
