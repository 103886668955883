import React from 'react'
import Topics from './topics'
import '../styles/main.css'
import letterBg from '../Assets/letter.png'

export default function Main(){
  const getDate = () => {
    const d = new Date()
    return `${d.getFullYear()}年${d.getMonth()+1}月${d.getDate()+1}日`
  }
  return (
    <main id="main">
      <section id="services">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3 className="section-title">礼拝時間と見学について</h3>
    {/*<p className="section-description"> </p>*/}

            <p className="section-description m-0 pb-5">金曜礼拝    フトバ：12:30    PM    ジュマー：1:10    PM <br/>Friday    Khutbah start    12:30    PM    Jummah Prayer Time 1:10    PM</p>
            <p className='table-heading'>本日の礼拝時間 Prayer Times for Kobe（{getDate()}）</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '600px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Date</th>
                    <th>Fajr</th>
                    <th>Sunrise</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>20</td>
                    <td>5:16</td>
                    <td>6:40</td>
                    <td>12:14</td>
                    <td>3:22</td>
                    <td>5:47</td>
                    <td>7:07</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <p className='table-heading'>神戸ムスリムモスクの礼拝時間 Iqama Times</p>
            <div className = "pb-5" style={{overflow:'auto', margin: '0 1rem'}}>
              <table className="table" style={{minWidth: '400px'}}>
                <thead className="thead-dark">
                  <tr>
                    <th>Fajr</th>
                    <th>Dhuhr</th>
                    <th>Asr</th>
                    <th>Magrib</th>
                    <th>Isha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6:00</td>
                    <td>12:30</td>
                    <td>4:00</td>
                    <td>5:44</td>
                    <td>7:45</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </section>
      <Topics/>

      <section id="services">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3 className="section-title">About Mosque</h3>
            
            <p className="section-description mx-auto" style={{maxWidth: "550px"}}>
                           Welcome to Kobe Muslim Mosque  <br/>
              All are invited to learn Quran in Kobe Muslim Mosque. <br/>
              神戸ムスリムモスクは、1935年（昭和10年）、神戸在住のトルコ人、タタール人、インド人貿易商らの出資により建てられた日本で最初のモスクです。当モスクは誰でも見学自由ですが、見学の際には、こちらの見学についてをお読み下さい。
            </p>
            <p className = "letter" style = {{backgroundImage: `url(${letterBg})`}}>
              Dear All, <br/>
              Assalam u alaikum, <br/>
              <br/>
              Congratulation to all of you Eid ul Adha 1443 Hijri (2022) in Japan will be 10th Jul on Sunday In Shaa Allah . <br/>
              Eid-Prayer will be offerad at 9am in Kobe Muslim Mosque. <br/>
              <br/>
              May Allah bless the whole Ummah and guide us to the right path. <br/>
              Massalam, <br/>
              <br/>
              Kobe Muslim Mosque <br/>
            </p>
          </div>
        </div>
      </section>

    </main>
  )
}
