import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import '../styles/navbar.css'

export default function Navbar(){
    const [nav, setNav] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const { pathname } = useLocation();

    const headerScrolled = () => {
      if (window.scrollY > 100) {
        setScrolled(true)
        // selectHeader.classList.add('header-scrolled')
      } else {
        setScrolled(false)
        // selectHeader.classList.remove('header-scrolled')
      }
    }

    useEffect(_=>{
      window.addEventListener('scroll', headerScrolled)
    }, [])

    const clickHandler = _ => {
      window.scrollTo(0, 0)
      setNav(false)
    }

    return (
    <header id="header" className={`fixed-top d-flex align-items-center header-transparent ${(scrolled || pathname!='/') ? "header-scrolled": ""}`}>
        <div className="container d-flex justify-content-between align-items-center">

          <div id="logo">
            <a href="index.html"><img src={require("../Assets/top_logo.png")} height={`${scrolled?'50px':'60px'}`} alt="" style={{transition:'all 0.5s'}}/></a>
          </div>

          <nav id="navbar" className={`navbar ${nav ? "navbar-mobile" : ""}`}>
            <ul>
              <li><Link to='/' className={`nav-link scrollto ${pathname=='/'?"active":""}`} href="#hero" onClick={clickHandler}>Home</Link></li>
              <li><Link to='/topics' className={`nav-link scrollto ${pathname.includes('topics')?"active":""}`} href="#about" onClick={clickHandler}>Topics</Link></li>
              <li><Link to='/prayertiming' className={`nav-link scrollto ${pathname.includes('prayertiming')?"active":""}`} href="#services" onClick={clickHandler}>Prayer Times</Link></li>
              <li><Link to='/halalfood' className={`nav-link scrollto ${pathname.includes('halalfood')?"active":""}`} href="#portfolio" onClick={clickHandler}>Halal Food</Link></li>
              <li><Link to='/access' className={`nav-link scrollto ${pathname.includes('access')?"active":""}`} href="#team" onClick={clickHandler}>Access</Link></li>
              <li><Link to='/icc' className={`nav-link scrollto ${pathname.includes('icc')?"active":""}`} href="#test" onClick={clickHandler}>Islamic Culture center</Link></li>
            </ul>
            <i className={`bi ${nav ? "bi-x" : "bi-list"} mobile-nav-toggle`} onClick={_=>setNav(!nav)}></i>
          </nav>
        </div>
    </header>
    )
}
